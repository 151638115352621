<template>
  <b-sidebar
    id="sidebar-send-invoice"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right
  >
    <template #default="{ hide }">
       <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Envoyer par Mail
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <div class="d-flex justify-content-center" v-if="sending">
        <div class="spinner-border text-secondary  mt-4" role="status"></div>
      </div>
      
      <div v-else>
        <!-- Body -->
        <b-form
          class="p-2"
          @submit.prevent
        >

          <!-- From -->
          <b-form-group
            label="De"
            label-for="from"
          >
            <b-form-input
              id="from"
              v-model="sendInvoiceData.from"
              trim
              type="email"
            />
          </b-form-group>

          <!-- To -->
          <b-form-group
            label="A"
            label-for="to"
          >
            <b-form-input
              id="to"
              v-model="sendInvoiceData.to"
              trim
              type="email"
            />
          </b-form-group>

          <!-- Subject -->
          <b-form-group
            label="Objet"
            label-for="Subject"
          >
            <b-form-input
              id="subject"
              v-model="sendInvoiceData.subject"
              trim
            />
          </b-form-group>

          <!-- Message -->
          <!-- <b-form-group
            label="Message"
            label-for="message"
          >
            <b-form-textarea
              id="message"
              v-model="sendInvoiceData.message"
              rows="12"
              trim
            />
          </b-form-group> -->

          <!-- ? File/Attachement -->
          <b-badge variant="light-primary">
            <feather-icon icon="LinkIcon" />
            <span class="ml-50">{{filename}}</span>
          </b-badge>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              @click="send()"
              :disabled="pdf ? false : true"
            >
              Envoyer
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              @click="hide"
            >
              Fermer
            </b-button>
          </div>
        </b-form>
      </div>
     

      
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormTextarea, BButton, BBadge,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import { mapActions } from 'vuex'
import TemplateMail from './template'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import localstorageService from '@/services/localstorage/localstorage.service'


export default {
  props: {
    formData: Object,
    pdf: Blob,
    currentSubject: String,
  },
  components: {
    BSidebar, BForm, BFormGroup, BFormInput, BFormTextarea, BButton, BBadge,
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  setup() {
    let userData =  localstorageService.getUserData();
    // console.log('formData: ', formData)
    let sendInvoiceData = ref({
      from: 'mtca.noreply@gouv.bj',
      senderFullname: 'MTCA NoReply',
      // to: userData ? userData.email : '',
      to: 'theontia@gmail.com',
      subject: '',
      // message: TemplateMail.model(userData.firstname+' '+ userData.lastname)
    })

    return {
      sendInvoiceData,
    }
  },
  data() {
    return {
      sending: false,
      filename: '',
    }
  },
  watch: {
    // formData: {
    //   immediate: true,
    //   deep: true,
    //   handler(val, old) {
    //   },
    // },
  },
  mounted(){
    // console.log('formData ---------------- : ', this.formData)
    // console.log('sendInvoiceData ---------------- : ', this.sendInvoiceData)
    // custom filename
    // if ( this.formData.nomEtablissement && this.formData.mois && this.formData.annee ) {
    //   this.filename = this.formData.nomEtablissement+this.formData.mois+this.formData.annee+'.pdf';
    //   console.log('A ---------------- : ',this.filename)
    // }
    // if ( this.formData.firstname && this.formData.lastname ) {
    //   this.filename = this.formData.firstname+this.formData.lastname+'.pdf';
    //   console.log('B ---------------- : ',this.filename)
    // }
    this.sendInvoiceData.subject = this.currentSubject
    this.filename = this.currentSubject+'.pdf'
    // console.log('this.filename ---------------- : ', this.filename)
    
  },
  methods: {
    ...mapActions("utils", {
      sendMail: "sendMail",
    }),

    send(){
      if(this.sendInvoiceData.to == ''){
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Veuillez renseigner le destinataire',
            // icon: 'CheckIcon',
            variant: 'danger',
            // text: 'Bienvenue !',
          },
        });
        return
      }
        
      // let filename = 'boss';
      // filename = this.formData.nomEtablissement && this.formData.mois && this.formData.annee ? this.formData.nomEtablissement+this.formData.mois+this.formData.annee+'.pdf' : '';
      // filename = this.formData.firstname && this.formData.lastname ? this.formData.firstname+this.formData.lastname+'.pdf' : ''
      this.sending = true;
      this.sendInvoiceData.attachedFileName = this.filename;
      this.sendInvoiceData.subject = this.currentSubject;
      this.filename = this.currentSubject+'.pdf'

      let formDataForSending = new FormData();
      formDataForSending.append('objectdata',JSON.stringify(this.sendInvoiceData) );
      formDataForSending.append('pdf', this.pdf ? this.pdf : null);

      // console.log('formDataForSending.get("objectdata"): ', formDataForSending.get("objectdata"))
      // console.log('formDataForSending.get("pdf"): ', formDataForSending.get("pdf"))

      //this.sendInvoiceData.attachedFile = this.pdf ? this.pdf : null,

      // return
      
      this.sendMail(formDataForSending)
        .then(res => {
          // console.log('res: ---------------------- ', res)
          if(res.status == 200){
            this.sending = false;
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Mail envoyé',
                // icon: 'CheckIcon',
                variant: 'success',
                // text: 'Bienvenue !',
              },
            });
            this.hide;
          }else{
            this.sending = false;
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Erreur d\'envoi',
                // icon: 'CheckIcon',
                variant: 'danger',
                // text: 'Bienvenue !',
              },
            });
          }
        })
        .catch(err => {
          console.log(err)
          this.isLoading = false
        })
    },
  },
}
</script>

<style>

</style>
